<template>
  <HeroProductSection
    :id="item.fields.id"
    :title="headline"
    :subtitle="subtitle"
    :text="text"
    :buttons="buttons"
    :media="media"
    :text-side="textSide"
    :color-mode="colorMode"
  >
  </HeroProductSection>
</template>

<script setup lang="ts">
import { HeroProductSection } from '@hypercodestudio/basler-components';
import type {
  HeadlineProps,
  TextSide,
  ColorMode
} from '@hypercodestudio/basler-components/dist/components/content-modules/sections/HeroProductSection.vue';
import { getLinkIconPosition } from '~/utils/getLinkIconPosition';
import type { IHeroProductSection } from '~/lib/ContentfulService';
import { useMedia } from '~/composables/useMedia';
import { isEntryResolveError } from '~/utils/guards/isEntryResolveError';
import { isDefined } from '~/utils/guards/isDefined';

interface Props {
  item: IHeroProductSection;
  colorMode: ColorMode;
}
const props = defineProps<Props>();

// Die HeadlineProps beinhalten aktuell nur einen content. Hypercode wurde in Ticket #1318 darüber informiert.
const headline = computed<HeadlineProps>(() => ({
  content: props.item.fields.title,
  badgeText: props.item.fields.badge?.fields.title
}));

const subtitle = computed<HeadlineProps | undefined>(() => {
  if (props.item.fields.subtitle) {
    return {
      content: props.item.fields.subtitle
    };
  }
});

// Der Link Type beinhaltet aktuell nur einen Text. Hypercode wurde in Ticket #1260 darüber informiert.
const buttons = computed(() =>
  props.item.fields.links
    ?.filter(isDefined)
    ?.filter((entry) => !isEntryResolveError(entry))
    ?.map((field) => ({
      text: field.fields.linkText,
      link: useBuildLinkInterface(field),
      size: field.fields.linkStyleSize,
      styleType: field.fields.linkStyle ?? 'default',
      iconName: field.fields.linkIcon,
      targetBlank: field.fields.linkTarget === '_blank',
      iconType: getLinkIconPosition(field.fields.linkIconPosition)
    }))
);

const text = computed(() => {
  if (props.item.fields.text) {
    return {
      lead: false,
      content: props.item.fields.text
    };
  }
});

const textSide = computed<TextSide>(
  () => props.item.fields.textAlignment ?? 'left'
);

const media = await useMedia(props.item.fields.media, {
  keepAspectRatio: true
});
</script>
